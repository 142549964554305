
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_PRODUCT_URL = BASE_API_URL+"/products"

export enum ProductsMixinTags {
  UpdateProduct = 'UpdateProduct'
}

@Component
export default class ProductsMixin extends Vue {

  getProducts(data: any, tag: string) {
    return this.$httpGetQuery(BASE_PRODUCT_URL, tag,data);
  }

  getProduct(id: number, tag: string) {
    return this.$httpGet(BASE_PRODUCT_URL+"/"+id, tag);
  }

  destroyProduct(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRODUCT_URL+"/"+id, tag);
  }

  createProduct(data: any, tag: string) {
    return this.$httpPost(BASE_PRODUCT_URL, tag, data);
  }

  updateProduct(data: any, tag: string) {
    return this.$httpPut(BASE_PRODUCT_URL+"/"+data.id, tag, data);
  }

  updateProductMultipart(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_PRODUCT_URL+"/"+id, tag, data);
  }

  uploadPrintFile(data: any, tag: string) {
    return this.$httpPost(BASE_PRODUCT_URL+"/upload_print_file", tag, data);
  }
  fullImport(data: any, tag: string) {
    return this.$httpPost(BASE_PRODUCT_URL+"/full_import", tag, data);
  }
}
