













import VPageContentSticky from '@/components/VPageContentSticky.vue';
import { Vue, Component, Prop, Mixins, Ref } from 'vue-property-decorator';
import ProductsMixin from '@/mixins/http/ProductsMixin';
import IncompleteModal from '@/components/Product/IncompleteModal.vue';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { namespace } from 'vuex-class';
import { Product, Source } from '@/models';
import StoreSubscriber from '@/store/StoreSubscriber';
import { easync } from '@/utils/http';
import { showToast } from '@/utils/Toast';
import { sourceStore } from '@/store/typed';

const tag = 'get_incomplete';
const source = namespace('source');
@Component({ components: { IncompleteModal } })
export default class IncompleteList extends Mixins(ProductsMixin) {
  @RegisterHttp(tag) readonly getIncompleteRequest!: RequestData;

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  created() {
  this.subscribe();
  }

  onSourceSet(source: Source | undefined) {
    this.items = [];

    if (source) {
      this.loadIncomplete();
    }
  }

  @Ref() incompleteModal: IncompleteModal;

  private items: Product[] = [];

  mounted() {
    this.loadIncomplete();
  }

  openModal() {
    this.incompleteModal.show(this.items);
  }

  get hasItems() {
    return this.items?.length;
  }

  async loadIncomplete() {
    const data = {
      included: ['name'],
      filter: {
        incomplete: sourceStore.currentSource.id,
        source: sourceStore.currentSource.id
      }
    };

    const [res, errors] = await easync(this.getProducts(data, tag));
    if (errors) {
      showToast("Si è verificato un errore");
      return;
    }

    this.items = res;
  }

  get loading() {
    return this.getIncompleteRequest?.loading;
  }

  get meta() {
    return this.getIncompleteRequest?.meta;
  }

  get totalItems() {
    return this.meta?.totalRows;
  }
}
