



































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import { Product } from "@/models";
@Component({
  components: {
    VModal
  }
})
export default class IncompleteProductsModal extends Vue {
  modal!: VModal;
  content = "";

  private items: Product[] = [];

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  title() {
    return `Prodotti da configurare`;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.items = [];
  }

  show(items: Product[]) {
    this.items = items;
    this.modal.show();
  }
}
